@import '~react-perfect-scrollbar/dist/css/styles.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

#nprogress .bar {
  z-index: '2000 !important';
}
